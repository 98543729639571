import React, { useEffect, useState } from 'react';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import { CustomToast } from '@src/components/toast';
// Types
import { InvoiceData, PaymentData } from './types';
// Components
import { PaginationComponent } from '@src/components/pagination';
// API
import { allInvoices, allPayments, getPaymentSetting, searchedInvoices, searchedPayments } from '@src/Api';
import { Container } from 'react-bootstrap';
import { AnimatePresence } from 'framer-motion';
import { InvoiceTab } from './invoiceTab';
import { PaymentTab } from './paymentTab';
import { Search } from '@src/components/search';
import { ScrollToTop } from '@src/components/scroll-to-top';

export const SyncOverviewPage: React.FC = () => {
  const [invoicesData, setInvoicesData] = useState<InvoiceData[]>([]);
  const [paymentData, setPaymentData] = useState<PaymentData[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalResults, setTotalResults] = useState<number>(25);
  const [resultsPerPage, setResultsPerPage] = useState<number>(25);
  const [toasts, setToasts] = useState<{ id: number; message: string }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentSettingActivated, setPaymentSettingActivated] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState('invoices');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [clearSearch, setClearSearch] = useState<boolean>(false);
  const [sortOrder, setSortOrder] = React.useState<'asc' | 'desc'>('desc');
  const [sortField, setSortField] = React.useState<keyof InvoiceData | keyof PaymentData | null>(null);

  const handleSelectTab = (key: string | null) => {
    if (key) {
      setActiveKey(key);
      setCurrentPage(0);
      setTotalResults(10);
      setInvoicesData([]);
      setPaymentData([]);
      setSortField(null);

      if (paymentSettingActivated) {
        localStorage.setItem('syncActiveTab', key);
      }
    }
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleClearSearch = () => {
    setClearSearch(true);
  };

  const handleSortChange = (field: keyof InvoiceData | keyof PaymentData | null, order: 'asc' | 'desc') => {
    setSortOrder(order);
    setSortField(field);
    setCurrentPage(0);
  };

  const fetchPaymentSetting = async () => {
    const paymentResponse = await getPaymentSetting();
    if (paymentResponse) {
      return paymentResponse.data;
    }
    return false;
  };

  useEffect(() => {
    const fetchSyncData = async () => {
      setLoading(true);

      if (clearSearch) {
        setSearchQuery('');
        setClearSearch(false);
      }

      const sortFieldString = sortField ? sortField.toString() : activeKey === 'invoices' ? 'invoices_id' : 'economic_entry_id';

      if (activeKey === 'invoices') {
        let response;
        if (searchQuery) {
          response = await searchedInvoices(resultsPerPage, currentPage, searchQuery, sortOrder, sortFieldString);
        } else {
          response = await allInvoices(resultsPerPage, currentPage, sortOrder, sortFieldString);
        }

        if (response) {
          setInvoicesData(response.data.results);
          setTotalResults(response.data.total);
        }
      }

      if (activeKey === 'payments') {
        let response;
        if (searchQuery) {
          response = await searchedPayments(resultsPerPage, currentPage, searchQuery, sortOrder, sortFieldString);
        } else {
          response = await allPayments(resultsPerPage, currentPage, sortOrder, sortFieldString);
        }

        if (response) {
          setPaymentData(response.data.results);
          setTotalResults(response.data.total);
        }
      }

      setLoading(false);
    };
    fetchSyncData();
  }, [sortField, sortOrder, currentPage, searchQuery, activeKey, resultsPerPage, toasts]);

  useEffect(() => {
    const initializeData = async () => {
      setLoading(true);

      const isPaymentSettingActivated = await fetchPaymentSetting();
      setPaymentSettingActivated(isPaymentSettingActivated);

      if (isPaymentSettingActivated) {
        const storedTab = localStorage.getItem('syncActiveTab');
        setActiveKey(storedTab || 'invoices');
      } else {
        setActiveKey('invoices');
      }
    };

    initializeData();
  }, [currentPage, resultsPerPage, activeKey, clearSearch]);

  const removeToast = (id: number) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  const handleDropdownChange = (value: number) => {
    setResultsPerPage(value);
  };

  return (
    <>
      <Container className="toast-b-right">
        <AnimatePresence>
          {toasts.map((toast, index) => (
            <CustomToast
              duration={3000}
              key={index}
              show={true}
              title={`Sync status for ID: ${toast.id}`}
              message={toast.message}
              type="success"
              onClose={() => removeToast(toast.id)}
            />
          ))}
        </AnimatePresence>
      </Container>

      <Row className="sync-overview">
        <Col className="p-0">
          <div>
            <h1 className="sync-overview-header">Sync overview</h1>
            <hr className="mt-2" />
          </div>

          <Row className="sync-overview-search">
            <Search
              searchQuery={searchQuery}
              handleSearch={handleSearch}
              handleInputChange={handleInputChange}
              handleClearSearch={handleClearSearch}
              placeholder="Search"
            ></Search>
          </Row>
          <div className="sync-overview-tabs">
            <Tabs activeKey={activeKey} id="sync-overview-tabs" onSelect={handleSelectTab}>
              <Tab eventKey="invoices" title="Invoices">
                <InvoiceTab invoiceData={invoicesData} loading={loading} setToasts={setToasts} onSortChange={handleSortChange} />
              </Tab>
              {paymentSettingActivated && (
                <Tab eventKey="payments" title="Payments">
                  <PaymentTab paymentData={paymentData} loading={loading} setToasts={setToasts} onSortChange={handleSortChange} />
                </Tab>
              )}
            </Tabs>
          </div>
          <Row className="sync-overview-footer">
            <Col>{activeKey === 'invoices' ? `Total invoices: ${totalResults}` : `Total payments: ${totalResults}`}</Col>
            <Col>
              <PaginationComponent
                itemsCount={totalResults}
                itemsPerPage={resultsPerPage}
                currentPage={currentPage + 1}
                setCurrentPage={(pageChangeTo) => setCurrentPage(pageChangeTo - 1)}
              />
            </Col>
            <Col className="sync-overview-footer-dropdown">
              <select className="form-select dropdown" value={resultsPerPage} onChange={(e) => handleDropdownChange(parseInt(e.target.value))}>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </Col>
          </Row>
        </Col>
      </Row>
      <ScrollToTop></ScrollToTop>
    </>
  );
};
